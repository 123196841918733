import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import axios from "axios";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./stripePromise";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { toastSuccess, toastError } from "../hooks/toaster";
import Model from "./model";
import AddAddress from "./addAddress";
import { ADMIN_ADDRESS, TOKEN_ABI, TOKEN_ADDRESS } from "../constant";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

function ProductSummary() {
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cartData, setCartData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [itemTotal, SetItemTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [payStatus, setPayStatus] = useState(false);
  const [addressStatus, setAddressStatus] = useState(false);
  const [address, setAddress] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shippingName, setShippingName] = useState();

  const [txLoading, settxLoading] = useState(false);

  const navigate = useNavigate();

  const stripeKey = loadStripe(stripePromise);

  useEffect(() => {
    fetchData();
    fetchAddress();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "product/cart-details/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setCartData(response.data);
        const taxCal = response.data.reduce(
          (sum, item) => sum + item.professional_product_fee,
          0
        );
        setTax(taxCal);
        const itemTotal = response.data.reduce(
          (sum, item) => sum + item.price,
          0
        );
        SetItemTotal(itemTotal);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchAddress = async () => {
    try {
      const response = await axios.get(
        API_URL + "product/shipping-address-by-user_id/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setAddress(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePay = () => {
    setAddressStatus(true);
    //setPayStatus(true);
  };

  const handlePayMent = () => {
    setPayStatus(true);
  };

  const handleDelete = async (id) => {
    setDeleteId(id);
    try {
      setDeleteLoading(true);
      const response = await axios.delete(
        API_URL + "product/delete-product-from-cart/" + id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        toastSuccess("Product deleted successfully");
        fetchData();
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
      console.error("Error fetching data:", error);
      toastError(error.response.data.message || "Product not deleted");
    }
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#ff0",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#87BBFD",
    },
  };

  const openAddressModal = () => {
    setModalIsOpen(true);
  };

  const handleDeleteAddress = async () => {
    try {
      setDeleteLoading(true);
      const response = await axios.delete(
        API_URL + "product/shipping-address/" + address[0]._id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        toastSuccess("Address deleted successfully");
        fetchAddress();
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
      console.error("Error fetching data:", error);
      toastError(error.response.data.message || "Address not deleted");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeUpdateModal = () => {
    setModalIsOpen(false);
    fetchAddress();
  };

  const handleShippingName = async (e) => {
    setShippingName(e.target.value);
  };

  const makeApiCalls = async () => {
    let arr = [];
    for (const apiUrl of cartData) {
      let transformedItem = "";

      transformedItem = {
        booking_id: apiUrl._id,
        service_amount: apiUrl.orignal_price,
        token_platform: "",
        network_fee_amount: apiUrl.professional_service_fee,
        // total_amount: parseInt(
        //   apiUrl.orignal_price + apiUrl.professional_service_fee,
        //   10
        // ),
        token_account: "",

        cart_id: apiUrl._id,
        product_amount: apiUrl.price,
        network_fee_amount: apiUrl.professional_product_fee,
        token_platform: "",
        // total_amount: parseInt(
        //   apiUrl.price + apiUrl.professional_product_fee,
        //   10
        // ),
        token_account: "",
      };

      arr.push(transformedItem);
    }
    return arr;
  };

  const payWithCrypto = async (amount) => {
    if (!window.ethereum) {
      alert("Metamask not detected. Please install Metamask");
      return;
    }

    try {
      settxLoading(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Check if wallet is connected
      const accounts = await provider.listAccounts();

      if (accounts.length === 0) {
        await provider.send("eth_requestAccounts", []);
      }

      const signer = provider.getSigner();
      const recipientAddress = ADMIN_ADDRESS;

      // Create ERC20 token contract instance
      const tokenContract = new ethers.Contract(
        TOKEN_ADDRESS,
        TOKEN_ABI,
        signer
      );

      // Check user balance
      const userAddress = await signer.getAddress();

      const userBalance = await tokenContract.balanceOf(userAddress);

      const transferAmount = ethers.utils.parseUnits(amount.toString(), 18); // Adjust decimals for your token

      if (userBalance.lt(transferAmount)) {
        alert("Insufficient token balance");
        return;
      }

      // Transfer tokens
      const tx = await tokenContract.transfer(recipientAddress, transferAmount);
      await tx.wait(1);

      const apiResults = await makeApiCalls();

      let formData = {};

      formData["cart_id_array"] = apiResults;
      formData["name"] = shippingName;

      formData["hash"] = tx.hash;
      formData["amount"] = amount;

      const response = await axios.post(
        API_URL + "product" + "/add-payment/crypto",
        formData,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        toastSuccess("Product" + "booked successfully");
        navigate("/ThankYou");
      }
    } catch (e) {
      console.log(e);
    } finally {
      settxLoading(false);
    }
  };

  return (
    <div className="mt-5">
      <div className="font-bold text-3xl">Product Summary</div>

      {txLoading && (
        <div
          style={{
            position: "fixed",
            inset: "0",
            zIndex: "50",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              border: "5px solid rgba(0, 0, 0, 0.1)",
              borderTop: "5px solid white",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      )}

      {!loading ? (
        <>
          {cartData && cartData.length > 0 ? (
            <div className=" grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xs:grid-cols-1 gap-2">
              <div className="">
                {cartData &&
                  cartData.map((cart) => {
                    return (
                      <div className="block">
                        <div className="mt-4 bg-grey1 p-3 rounded inline-block lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[85%] xxs:w-[85%] mr-3">
                          <div className="inline-block">
                            <div className="inline-block align-top">
                              {cart.product_id.img_urls &&
                              cart.product_id.img_urls.length > 0 ? (
                                <img
                                  className="rounded mr-3 max-h-[40px] inline-block"
                                  src={cart.product_id.img_urls[0]}
                                  alt="productImg"
                                />
                              ) : (
                                <img
                                  src="img/noImg.jpg"
                                  className={` max-h-[40px] mr-3 rounded`}
                                />
                              )}
                            </div>
                            <div className="inline-block text-sm">
                              <div className="font-bold  inline-block">
                                {cart.product_id.name}
                              </div>
                              <div className=" font-bold">
                                Qty :{" "}
                                <span className="font-normal">
                                  {cart.quantity}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={` ${
                              matches ? "" : "float-right text-right"
                            } inline-block `}
                          >
                            <div className="font-bold text-green1">
                              ${cart.product_id.price}
                            </div>
                          </div>
                        </div>
                        {deleteLoading && deleteId === cart._id ? (
                          <Spinner />
                        ) : (
                          <button
                            onClick={() => handleDelete(cart._id)}
                            className="inline-block w-[7%]"
                          >
                            <img
                              className="h-[20px] w-[20px]"
                              src="img/cross.png"
                            />
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="bg-grey1 p-3 mt-4 rounded">
                <div className="font-bold">Coupons</div>
                <div className="w-full mt-3">
                  <input
                    type="text"
                    className="p-2 w-4/5 border border-grey-9 rounded"
                    placeholder="Enter coupon code"
                  />
                  <button className="w-[17%] text-green1 ml-2 font-bold">
                    Check
                  </button>
                </div>
                <div className="font-bold border-b border-black pb-3 mt-3">
                  Payment summary
                </div>
                <div className="block mt-2">
                  <div className="  inline-block">Items Total </div>
                  <div className="inline-block float-right">
                    ${itemTotal.toFixed(2)}
                  </div>
                </div>
                <div className="block mt-2 pb-3 border-b border-black border-dashed">
                  <div className="  inline-block">Network Fees </div>
                  <div className="inline-block float-right">${tax}</div>
                </div>
                <div className="block mt-2 pb-3 border-b border-black border-dashed">
                  <div className="  inline-block">Coupon Discount </div>
                  <div className="inline-block float-right">$0</div>
                </div>
                <div className="block mt-2">
                  <div className="  inline-block">Amount to pay </div>
                  <div className="inline-block float-right text-green1 font-bold">
                    ${(itemTotal + tax).toFixed(2)}
                  </div>
                </div>

                <div className="mt-5">
                  <a
                    onClick={() => handlePay()}
                    href="#"
                    className="rounded bg-green1 text-white w-full p-2 w-full inline-block text-center"
                  >
                    Proceed
                    {/* Pay ${itemTotal.toFixed(2)} */}
                    {/* Pay ${(itemTotal + tax).toFixed(2)} */}
                  </a>
                </div>

                {addressStatus ? (
                  <div className="mt-3">
                    <div>
                      <span className="font-bold text-sm">Shipping Name :</span>
                      <input
                        className="p-2 ml-1 rounded border border-grey9"
                        name="shipping_name"
                        value={shippingName}
                        onChange={handleShippingName}
                      />
                    </div>
                    {address && address.length > 0 ? (
                      <div className="">
                        <div className="font-bold text-sm mb-1 mt-1">
                          Shipping Address :{" "}
                        </div>
                        <a
                          className="inline-block align-sub"
                          href="#"
                          onClick={openAddressModal}
                        >
                          <img src="img/edit.png" />
                        </a>
                        <a
                          className="inline-block"
                          href="#"
                          onClick={handleDeleteAddress}
                        >
                          <img className="h-[15px] " src="img/Delete2.png" />
                        </a>
                        <span className="inline align-top ml-1">
                          {address && address[0] ? (
                            <>
                              {address[0].address_line1
                                ? address[0].address_line1 + " "
                                : ""}
                              {address[0].address_line2
                                ? address[0].address_line2 + " "
                                : ""}
                              {address[0].city ? address[0].city + " " : ""}
                              {address[0].state ? address[0].state + " " : ""}
                              {address[0].country
                                ? address[0].country + " "
                                : ""}
                              {address[0].pincode ? address[0].pincode : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <div>
                          <a
                            onClick={() => handlePayMent()}
                            href="#"
                            className="mt-2  rounded bg-green1 text-white w-full p-2 w-full inline-block text-center"
                          >
                            Pay ${(itemTotal + tax).toFixed(2)}
                          </a>
                        </div>

                        <div
                          style={{
                            padding: "7px 0",
                          }}
                        >
                          <h2 className="text-center text-grey3 text-[13px]">
                            OR
                          </h2>
                        </div>

                        <div className="">
                          <button
                            onClick={() =>
                              payWithCrypto(
                                Number((itemTotal + tax).toFixed(2))
                              )
                            }
                            className="rounded bg-green1 text-white w-full p-2 w-full inline-block text-center"
                          >
                            Pay with crypto
                          </button>
                        </div>
                      </div>
                    ) : (
                      <a
                        className="inline-block mt-2 rounded p-2 border border-grey3 bg-white"
                        href="#"
                        onClick={openAddressModal}
                      >
                        Add Address
                      </a>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {payStatus ? (
                  <div className="stripePay">
                    <div className="mt-3 p-3 border border-grey3 rounded">
                      <Elements
                        stripe={stripeKey}
                        options={{
                          style: {
                            base: inputStyle,
                          },
                        }}
                      >
                        <CheckoutForm
                          cartData={cartData}
                          //totalAmt={(itemTotal + tax).toFixed(2)}
                          totalAmt={itemTotal.toFixed(2)}
                          shippingName={shippingName}
                          page="product"
                        />
                      </Elements>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="mt-10 font-bold text-lg text-center">
              Your Cart is empty
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <AddAddress
            closeModal={closeModal}
            closeUpdateModal={closeUpdateModal}
            primaryDetail={address && address[0] ? address[0] : ""}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
}

export default ProductSummary;
