import React, { useState, useEffect } from "react";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePromise } from "./stripePromise";
import CheckoutForm from "./CheckoutForm";
import Spinner from "./spinner";
import axios from "axios";
import { toastSuccess, toastError } from "../hooks/toaster";
import "../css/CheckoutForm.css";
import { getDateFormate } from "../hooks/commonFunctions";
import RecurringPay from "./recurringPay";
import Model from "./model";
import CancellationModal from "./cancellationModal";
import { ADMIN_ADDRESS, TOKEN_ABI, TOKEN_ADDRESS } from "../constant";

import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

function PaymentSummary({ userId, paymentMode, payCash }) {
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const matches = UseMediaQuery("(max-width: 992px)");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cartData, setCartData] = useState();
  const [tax, setTax] = useState(0);
  const [itemTotal, SetItemTotal] = useState(0);
  const [deleteId, setDeleteId] = useState();
  const [payStatus, setPayStatus] = useState(false);
  const [loadingRecurring, setLoadingRecurring] = useState(false);
  const [recurringId, setRecurringId] = useState();
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState();
  const [cancellationText, setCancellationText] = useState("");
  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
  const [txLoading, settxLoading] = useState(false);

  const navigate = useNavigate();

  const stripeKey = loadStripe(stripePromise);
  let user_id = "";

  if (userInfo) {
    user_id = userId ? userId : userInfo.user_id;
  }
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "service/get-booking-service/" + user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setCartData(response.data);
        const taxCal = response.data.reduce((sum, item) => sum + item.tax, 0);
        setTax(taxCal);
        const itemTotal = response.data.reduce(
          (sum, item) => sum + item.orignal_price,
          0
        );
        SetItemTotal(itemTotal);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id, bookingId, SlotId) => {
    setDeleteId(id);
    try {
      setDeleteLoading(true);
      const response = await axios.delete(
        API_URL + "service/booking-service/" + id + "/" + SlotId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        toastSuccess("Service deleted successfully");
        fetchData();
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
      console.error("Error fetching data:", error);
      toastError(error.response.data.message || "Service not deleted");
    }
  };

  const handlePay = () => {
    if (paymentMode && paymentMode === "cash") {
      payCash();
    } else {
      setPayStatus(true);
    }
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#ff0",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#87BBFD",
    },
  };

  const handleRecurringModal = (booking_id) => {
    setIsOpenModal(true);
    setSelectedBookingId(booking_id);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const handleCancellation = (policy) => {
    setIsOpenModalCancel(true);
    setCancellationText(policy);
  };

  const closeModalCancel = () => {
    setIsOpenModalCancel(false);
  };

  const handleRecurring = async (val, bookingId) => {
    setRecurringId(bookingId);
    let status = {};
    status["recurring_status"] = !val;
    setLoadingRecurring(true);
    try {
      const response = await axios.put(
        API_URL + "service/update-recurring-status/" + bookingId,
        status,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        fetchData();
        toastSuccess("Recurring Enabled for the service");
        setLoadingRecurring(false);
        setRecurringId("");
      }
    } catch (error) {
      setLoadingRecurring(false);
      toastError("Recurring not enabled for service");
      console.error("Error fetching data:", error);
      setRecurringId("");
    }
  };

  const makeApiCalls = async () => {
    let arr = [];
    for (const apiUrl of cartData) {
      let transformedItem = "";

      transformedItem = {
        booking_id: apiUrl._id,
        service_amount: apiUrl.orignal_price,
        token_platform: "",
        network_fee_amount: apiUrl.professional_service_fee,
        // total_amount: parseInt(
        //   apiUrl.orignal_price + apiUrl.professional_service_fee,
        //   10
        // ),
        token_account: "",
      };

      arr.push(transformedItem);
    }
    return arr;
  };

  const payWithCrypto = async (amount) => {
    if (!window.ethereum) {
      alert("Metamask not detected. Please install Metamask");
      return;
    }

    try {
      settxLoading(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Check if wallet is connected
      const accounts = await provider.listAccounts();

      if (accounts.length === 0) {
        await provider.send("eth_requestAccounts", []);
      }

      const signer = provider.getSigner();
      const recipientAddress = ADMIN_ADDRESS;

      // Create ERC20 token contract instance
      const tokenContract = new ethers.Contract(
        TOKEN_ADDRESS,
        TOKEN_ABI,
        signer
      );

      // Check user balance
      const userAddress = await signer.getAddress();

      const userBalance = await tokenContract.balanceOf(userAddress);

      const transferAmount = ethers.utils.parseUnits(amount.toString(), 18); // Adjust decimals for your token

      if (userBalance.lt(transferAmount)) {
        alert("Insufficient token balance");
        return;
      }

      // Transfer tokens
      const tx = await tokenContract.transfer(recipientAddress, transferAmount);
      await tx.wait(1);

      const apiResults = await makeApiCalls();

      let formData = {};

      formData["booking_id_array"] = apiResults;
      formData["hash"] = tx.hash;
      formData["amount"] = amount;

      const response = await axios.post(
        API_URL + "service" + "/add-payment/crypto",
        formData,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        toastSuccess("Service" + " booked successfully");
        navigate("/ThankYou");
      }
    } catch (e) {
      console.log(e);
    } finally {
      settxLoading(false);
    }
  };

  return (
    <div className="">
      {txLoading && (
        <div
          style={{
            position: "fixed",
            inset: "0",
            zIndex: "50",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              border: "5px solid rgba(0, 0, 0, 0.1)",
              borderTop: "5px solid white",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      )}

      <div className=" mb-5">
        {/* <div className="mt-3 border-b border-grey2 pb-2">
          <div className="pb-2">
            <OuterMenu page="other" cartClr="black" />
          </div>
        </div> */}

        {/* <div className="mt-12 xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10 xxs:px-4"> */}
        <div className="mt-5">
          <div className="font-bold text-3xl">Payment Summary</div>
          {!loading ? (
            <>
              {cartData && cartData.length > 0 ? (
                <div className=" grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xs:grid-cols-1 gap-2">
                  <div className="">
                    {cartData &&
                      cartData.map((cart) => {
                        return (
                          <div className="block">
                            <div className="mt-4 bg-grey1 p-3 rounded inline-block lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[85%] xxs:w-[85%] mr-3">
                              <div className="inline-block">
                                <div className="font-bold text-lg">
                                  {cart.service_id.service_name}
                                </div>
                                <div className="text-grey3 text-xs">
                                  {cart.service_id.description}
                                </div>
                              </div>
                              <div
                                className={` ${
                                  matches ? "" : "float-right text-right"
                                } inline-block `}
                              >
                                <div className="font-bold text-green1">
                                  ${cart.service_id.price}
                                </div>
                                <div className="text-grey3 text-xs">
                                  {getDateFormate(cart.day)} |{" "}
                                  {cart.booking_time} | {cart.service_id.period}
                                  Min
                                </div>
                              </div>
                              <div className="block">
                                <button
                                  onClick={() => handleRecurringModal(cart._id)}
                                  className="px-2 py-1 mt-1 text-sm border border-grey9 bg-white rounded "
                                >
                                  Recurring Appointment
                                </button>
                                {/* Recurring Appointment */}
                                {/* {!loadingRecurring ? (
                                  <label class="switch ml-2 align-middle">
                                    <input
                                      type="checkbox"
                                      checked={cart.recurring_status}
                                      onClick={(e) =>
                                        handleRecurring(
                                          cart.recurring_status,
                                          cart._id
                                        )
                                      }
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                ) : (
                                  <>
                                    {recurringId === cart._id ? (
                                      <Spinner customClass="spinnerLike" />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )} */}
                              </div>
                              <div className="inline-block">
                                <a
                                  className="text-green1 text-sm"
                                  href="pdf/recurring_guidlines.pdf"
                                  download
                                >
                                  Recurring Guidelines
                                </a>
                              </div>
                              <div className="inline-block ml-2">
                                <button
                                  className="text-green1 text-sm"
                                  onClick={() =>
                                    handleCancellation(
                                      cart.seller_user_id.cancellation_policy
                                    )
                                  }
                                >
                                  Cancellation Policy
                                </button>
                              </div>
                            </div>
                            {deleteLoading && deleteId === cart._id ? (
                              <Spinner />
                            ) : (
                              <button
                                onClick={() =>
                                  handleDelete(
                                    cart._id,
                                    cart.booking_id,
                                    cart.slot_id
                                  )
                                }
                                className="inline-block w-[7%]"
                              >
                                <img
                                  className="h-[20px] w-[20px]"
                                  src="img/cross.png"
                                />
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className="bg-grey1 p-3 mt-4 rounded">
                    <div className="font-bold">Coupons</div>
                    <div className="w-full mt-3">
                      <input
                        type="text"
                        className="p-2 w-4/5 border border-grey-9 rounded"
                        placeholder="Enter coupon code"
                      />
                      <button className="w-[17%] text-green1 ml-2 font-bold">
                        Check
                      </button>
                    </div>
                    <div className="font-bold border-b border-black pb-3 mt-3">
                      Payment summary
                    </div>
                    <div className="block mt-2">
                      <div className="  inline-block">Items Total </div>
                      <div className="inline-block float-right">
                        ${itemTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="block mt-2 pb-3 border-b border-black border-dashed">
                      <div className="  inline-block">Network Fees </div>
                      <div className="inline-block float-right">${tax}</div>
                    </div>
                    <div className="block mt-2 pb-3 border-b border-black border-dashed">
                      <div className="  inline-block">Coupon Discount </div>
                      <div className="inline-block float-right">$0</div>
                    </div>
                    <div className="block mt-2">
                      <div className="  inline-block">Amount to pay </div>
                      <div className="inline-block float-right text-green1 font-bold">
                        ${(itemTotal + tax).toFixed(2)}
                      </div>
                    </div>

                    <div className="mt-5">
                      <a
                        onClick={() => handlePay()}
                        href="#"
                        className="rounded bg-green1 text-white w-full p-2 w-full inline-block text-center"
                      >
                        Pay {paymentMode ? paymentMode : ""} $
                        {(itemTotal + tax).toFixed(2)}
                      </a>
                    </div>

                    <div
                      style={{
                        padding: "7px 0",
                      }}
                    >
                      <h2 className="text-center text-grey3 text-[13px]">OR</h2>
                    </div>

                    <div className="">
                      <button
                        onClick={() =>
                          payWithCrypto(Number((itemTotal + tax).toFixed(2)))
                        }
                        className="rounded bg-green1 text-white w-full p-2 w-full inline-block text-center"
                      >
                        Pay with crypto
                      </button>
                    </div>

                    {payStatus ? (
                      <div className="stripePay">
                        <div className="mt-3 p-3 border border-grey3 rounded">
                          <Elements
                            stripe={stripeKey}
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                          >
                            <CheckoutForm
                              cartData={cartData}
                              totalAmt={(itemTotal + tax).toFixed(2)}
                              page="service"
                            />
                          </Elements>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="mt-10 font-bold text-lg text-center">
                  Your Cart is empty
                </div>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </div>
        {/* </div> */}

        {/* <div className="mt-5">
          <OuterFooter />
        </div>
        {matches ? <Footer /> : ""} */}
      </div>
      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={
          <RecurringPay closeModal={closeModal} bookingId={selectedBookingId} />
        }
        dialogClassName="modal-width-30"
      />
      <Model
        modalIsOpen={isOpenModalCancel}
        closeModal={closeModalCancel}
        content={
          <CancellationModal
            closeModal={closeModalCancel}
            cancellationText={cancellationText}
          />
        }
        dialogClassName="modal-width-40"
      />
    </div>
  );
}

export default PaymentSummary;
